var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText || "Información actualizada correctamente.")+" ")]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"7","md":"7"}},[_c('h1',[_vm._v(" Clientes de "),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.getBusinessName)+" ")])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.addCustomer()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" Vincular cliente ")],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.$store.state.search,"items-per-page":15,"fixed-header":"","loading":_vm.loading,"sort-by":['position'],"sort-desc":[false, true],"item-key":"id","height":"75vh","footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                    { active: item.active },
                    item['.key'],
                    item,
                    'active'
                  )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:`item.priceListId`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getListName(item))+" ")]}},{key:`item.email`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block","width":"100%","text-align":"center!important"},attrs:{"color":_vm.$vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.email)}}},on),[_vm._v(" "+_vm._s(item.email)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])])]}},{key:`item.phone`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block","width":"100%","text-align":"center!important"},attrs:{"color":_vm.$vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.phone)}}},on),[_vm._v(" "+_vm._s(item.phone)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])])]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"small":"","color":"red darken-4"},on:{"click":function($event){_vm.itemToDelete = item;
                _vm.modalDelete = true;}}},[_vm._v(" Eliminar ")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1)],1),(_vm.modalAddCustomer)?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.modalAddCustomer),callback:function ($$v) {_vm.modalAddCustomer=$$v},expression:"modalAddCustomer"}},[_c('add-customer',{attrs:{"customers":_vm.customers,"userToEdit":_vm.itemToEdit,"type":_vm.type},on:{"success":_vm.handleSuccess,"cancel":function($event){_vm.modalAddCustomer = false}}})],1):_vm._e(),(_vm.itemToDelete)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirmar acción "),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){_vm.modalDelete = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_vm._v(" ¿Está seguro que desea eliminar "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.itemToDelete.name)+" ")]),_vm._v("? ")]),_c('v-card-actions',{staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"border-radius":"15px"},attrs:{"color":"red darken-4","width":"90%","dark":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }