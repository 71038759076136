import { render, staticRenderFns } from "./customers.vue?vue&type=template&id=32f7c0ae&scoped=true"
import script from "./customers.vue?vue&type=script&lang=js"
export * from "./customers.vue?vue&type=script&lang=js"
import style0 from "./customers.vue?vue&type=style&index=0&id=32f7c0ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f7c0ae",
  null
  
)

export default component.exports