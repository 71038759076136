<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText || "Información actualizada correctamente." }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="7" md="7">
          <h1>
            Clientes de
            <span class="font-weight-light">
              {{ getBusinessName }}
            </span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="addCustomer()"
              :loading="loading"
            >
              <v-icon right dark class="mr-3">
                fas fa-plus
              </v-icon>
              Vincular cliente
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="customers"
            :search="$store.state.search"
            :items-per-page="15"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            :sort-desc="[false, true]"
            item-key="id"
            height="75vh"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <div style="display:flex;justify-content:center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item,
                      'active'
                    )
                  "
                  v-model="item.active"
                >
                </v-switch>
              </div>
            </template>

            <template v-slot:[`item.priceListId`]="{ item }">
              {{ getListName(item) }}
            </template>

            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display:inline-block; width:100%; text-align: center!important;"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.email)"
                  >
                    {{ item.email }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display:inline-block; width:100%; text-align: center!important;"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.phone)"
                  >
                    {{ item.phone }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="editUser(item)" class="ma-2" small color="primary">
                Editar
              </v-btn>
              <v-btn
                class="ma-2 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
              >
                Eliminar
              </v-btn>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position:relative;">
                <div
                  style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                >
                </v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      persistent
      v-if="modalAddCustomer"
      v-model="modalAddCustomer"
      max-width="600px"
    >
      <add-customer
        :customers="customers"
        :userToEdit="itemToEdit"
        :type="type"
        @success="handleSuccess"
        @cancel="modalAddCustomer = false"
      />
    </v-dialog>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right:10px; top:10px;"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold"> {{ itemToDelete.name }} </span>?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius:15px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import addCustomer from "./add-customer";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "customers",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    addCustomer,
  },
  data() {
    return {
      business: null,
      item: 0,
      randomKey: 0,
      customers: [],
      itemToDelete: null,
      itemToEdit: "",
      modalDelete: false,
      modalAddCustomer: false,
      priceLists: [
        { name: "Lista 1", value: "priceList1" },
        { name: "Lista 2", value: "priceList2" },
        { name: "Lista 3", value: "priceList3" },
        { name: "Lista 4", value: "priceList4" },
        { name: "Lista 5", value: "priceList5" },
        { name: "Lista 6", value: "priceList6" },
        { name: "Lista 7", value: "priceList7" },
        { name: "Lista 8", value: "priceList8" },
      ],
      headers: [
        { text: "Código del cliente", value: "code" },
        { text: "Negocio", value: "business", width: "150px" },
        { text: "RTN", value: "tin" },
        { text: "Nombre del cliente", value: "name", width: "150px" },
        { text: "Celular", value: "phone" },
        { text: "Correo electrónico", value: "email" },
        { text: "Lista de precios", value: "priceListId", width: "150px" },
        { text: "Activa", value: "active", align: "center", justify: "center" },
        { text: "", value: "options", align: "end", width: "250px" },
      ],
      loading: true,
      snackbarText: "",
      snackbar: "",
      type: "",
      saving: false,
    };
  },
  watch: {
    selectedBusiness() {
      this.discounts = [];
      this.getBusinessData();
    },
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("LL");
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
  },
  methods: {
    moment,
    getListName(customer) {
      return this.priceLists.find((item) => item.value == customer.priceList)
        ? this.priceLists.find((item) => item.value == customer.priceList).name
        : "";
    },
    handleSuccess(result) {
      this.modalAddCustomer = false;
      this.snackbarText = result;
      this.snackbar = true;
    },
    addCustomer() {
      this.randomKey = Math.random();
      this.type = "new";
      this.modalAddCustomer = true;
    },
    editUser(item) {
      this.itemToEdit = item;
      this.randomKey = Math.random();
      this.type = "edit";
      this.modalAddCustomer = true;
    },
    switchControlChanged(data, customerId, customer, field) {
      if (data && customerId) {
        this.saving = true;
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];

        let prevData = Object.assign({}, data);
        prevData[field] = !data[field];

        db.collection(`businesses/${this.selectedBusiness[".key"]}/customers`)
          .doc(customerId)
          .update(data)
          .then(() => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;

            this.createLedger({
              source_id: customerId,
              source_type: "customers",
              currentValue: data,
              prevValue: prevData,
            });
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            customer.active = !customer.active;
          });
      }
    },
    confirmDelete: function() {
      if (this.itemToDelete) {
        db.collection(`businesses/${this.selectedBusiness[".key"]}/customers`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";

            this.createLedger({
              source_id: this.itemToDelete[".key"],
              source_type: "customers",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt:  new Date(),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: {
                deleted: this.itemToDelete.deleted,
                active: this.itemToDelete.active,
              },
            });
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    async getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.loading = true;
      this.tagsToSearch = "";
      this.$store.state.search = "";

      let customers = this.$binding(
        "customers",
        db
          .collection(`businesses/${this.selectedBusiness[".key"]}/customers`)
          .where("deleted", "==", false)
      ).then(() => {
        this.loading = false;
      });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "Clientes";
    this.getBusinessData();
  },
};
</script>

<style scoped>
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
</style>
